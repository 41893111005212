<template>
  <v-app-bar app class="appbar" clipped-left :class="classCordova">
    <v-row no-gutters :align="'center'">
      <v-col
        cols="1"
        class="justify-left align-center"
        v-if="$routerHistory.hasPrevious()"
      >
        <v-btn
          v-if="$routerHistory.hasPrevious() && $platform_is_cordova"
          @click.prevent.stop="historyBack()"
          class="no-border"
          aria-label="Torna Indietro"
          icon
          :ripple="false"
        >
          <v-icon class="default--text back-arrow">$chevronLeft</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" md="2" lg="2" xl="1" class="justify-center align-center">
        <img
          class="px-0 logo"
          src="/logo/alì_logo_MV_2020.svg"
          alt="Logo Alisupermercati.it"
          height="40"
        />
      </v-col>
      <v-col
        :cols="$routerHistory.hasPrevious() ? 7 : 8"
        md="7"
        class="d-flex flex-row justify-center align-center px-md-2 "
        style="flex-wrap: nowrap;"
      >
        <div
          style="min-width: 100px; max-width: 100%;"
          class="flex-grow-1 flex-shrink-0 pl-2"
        >
          <SearchInput />
        </div>
      </v-col>
      <v-col cols="2" md="2" class="d-flex justify-end justify-md-end">
        <v-btn
          icon
          tile
          class="btn-avatar border rounded"
          height="40"
          width="40"
          @click.stop="drawerLinks = !drawerLinks"
        >
          <v-icon>$dots</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style lang="scss">
.btn-avatar {
  background: #fff6e4 0% 0% no-repeat padding-box;
  border-radius: 8px;
}

.shopping-cart-icon {
  color: var(--v-primary-base) !important;
}

@media screen and (max-width: 1360px) {
  .username {
    display: none !important;
  }
}

.v-main {
  background-color: white;
  //padding-top: calc(80px + env(safe-area-inset-top)) !important;
}

.v-app-bar--fixed {
  background-color: #fcfcfc !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  // iOS 11.0 fixes
  height: calc(
    #{$statusbarheight} + constant(safe-area-inset-top)
  ) !important; // % ($statusbarheight constant(safe-area-inset-top))
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  // iOS 11.2 fixes
  height: calc(
    #{$statusbarheight} + env(safe-area-inset-top)
  ) !important; // ($statusbarheight env(safe-area-inset-top));
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-app-bar--fixed {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheight;
    width: 100%;
    // iOS 11.0 fixes
    height: calc(
      #{$statusbarheight} + constant(safe-area-inset-top)
    ) !important;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    // iOS 11.2 fixes
    height: calc(#{$statusbarheight} + env(safe-area-inset-top)) !important;
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}

.back-arrow {
  font-size: 20px !important;
  height: 20px !important;
  width: 20px !important;
}

.router-back {
  text-decoration: none;
}

.router-back,
.noop {
  .theme--light.v-btn:before {
    background: white !important;
  }
}

.is-cordova {
  .v-toolbar__content,
  .v-toolbar__extension {
    padding: 4px 8px;
  }
}
</style>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import SearchInput from "@/components/navigation/SearchInput.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";

import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login, cartInfo],
  components: { SearchInput },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    goBack() {
      this.$router.go(-1);
    },
    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          if (this.$vuetify.breakpoint.xs && this.editableCart) {
            this.$router.push("/checkout");
          } else {
            this.drawerRight = !this.drawerRight;
          }
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      return this.cart.totalItems;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    classCordova: function() {
      return {
        "is-cordova": typeof cordova !== "undefined"
      };
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
};
</script>
